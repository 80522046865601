import React ,{useState,useEffect, useRef}from "react";
import "./countries.css";
import { CountriesData } from "./Data";

const Countries = ({eng}) => {
    const [boxData, setBoxData] =useState(CountriesData)
    const [index,setIndex]= useState(0)

 
    setTimeout(()=>{
   
        if (index === 0 ) {
            let res= boxData
            res[index].gray=false 
            setBoxData(res)
            setIndex(index+1)
        }
        else if(index<boxData.length ){
            let res= boxData
            res[index-1].gray=true
            res[index].gray=false 
            setBoxData(res)
            setIndex(index+1)
        }else {
            let res= boxData
            res[res.length-1].gray=true
            setBoxData(res)
            setIndex(0)
        }

    },3000)


       
    useEffect(()=> {
   
      const handleScroll = (e)=> {
        console.log("countries inside handle scroll")
        return panelSlideIn();
      }
      console.log("countries inside use effect")
      window.addEventListener('scroll', handleScroll);
      },[]);

  
      
       const CountreisPannel = useRef();
      const panelSlideIn = () => {
            console.log("insidepannelslidein")
        const slideInAt = (window.scrollY + window.innerHeight) -  CountreisPannel.current.offsetHeight / 4;
        const panelMidpoint = CountreisPannel.current.offsetTop + CountreisPannel.current.offsetHeight /4;
        if (slideInAt > panelMidpoint) {
          console.log("classNameadded countries")
          CountreisPannel.current.classList.add('countries-fade-out');
        }
      
      }
  

  return (
    <div className="countries-container" id="clients" >
      <div className="countreis-second-container" ref={CountreisPannel}>
      <div className="countries-title">{eng ?"Our international markets" : "Nos marchés a l'international" }</div>
      <div className="countries-description">{eng ?` RTCA has gained access to strategic international markets.`:`la société RTCA  a réussi à accéder à des marchés internationaux stratégiques.`}</div>
      <div className="countries-images-container">
        {boxData.map((e,i)=>{
            return (
                <div key={i} className={e.gray ? "countries-image-name" : "countries-image-name activeGray"}>
                 <img src={e.image} alt="" />
                 <div className="countries-img-name">{eng? e.nameEn : e.nameFr}</div>
                </div>
            )
        })}
      </div>
      </div>
    </div>
  );
};

export default Countries;
