import React,{useEffect,useState,useRef} from 'react'
import "./solution.css"


const Solution = ({eng}) => {
  return (
    <div className='solution-main-container'>
      <div className="container">
  <div className="card">
     <div className="card__image-container">
       <img className="card__image" src="https://images.unsplash.com/photo-1613690413712-dbe316605644?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2059&q=80" alt="" />
    </div>
      
      <svg className="card__svg" viewBox="0 0 800 500">

        <path d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500" stroke="transparent" fill="#333"/>
        <path className="card__line" d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400" stroke="pink" strokeWidth="8" fill="transparent"/>
      </svg>
    
     <div className="card__content">
       <h1 className="card__title">{eng ?"Export all product types." :"Exportez tous types de produits"} </h1>

    </div>
 
  </div>

  <div className="card">
     <div className="card__image-container">
       <img className="card__image" src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80" alt="" />
    </div>
      
      <svg className="card__svg" viewBox="0 0 800 500">

        <path d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500" stroke="transparent" fill="#333"/>
        <path className="card__line" d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400" stroke="pink" strokeWidth="8" fill="transparent"/>
      </svg>
    
     <div className="card__content">
       <h1 className="card__title"> {eng ?"International negotiation of your products":" Négociation de vos produits à l'échelle internationale"}.</h1>

    </div>
    
  </div>
  <div className="card">
     <div className="card__image-container">
       <img className="card__image" src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80" alt="" />
    </div>
      
      <svg className="card__svg" viewBox="0 0 800 500">

        <path d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500" stroke="transparent" fill="#333"/>
        <path className="card__line" d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400" stroke="pink" strokeWidth="8" fill="transparent"/>
      </svg>
    
     <div className="card__content">
       <h1 className="card__title">{eng ? "Over seven years experience in this field":"Plus de sept années d'expérience dans ce domaine"}.</h1>

    </div>
   
  </div>
</div>



    </div>
  )
}


// const Solution = ({eng}) => {
 
//   useEffect(()=> {
//     console.log("Hola")
//     const handleScroll = (e)=> {
//       return panelSlideIn();
//     }
//     window.addEventListener('scroll', handleScroll);
//     },[]);
    
//      const SolutionPannel = useRef();
//     const panelSlideIn = () => {
          
           
//       const slideInAt = (window.scrollY + window.innerHeight) -  SolutionPannel.current.offsetHeight / 4;
//       const panelMidpoint = SolutionPannel.current.offsetTop + SolutionPannel.current.offsetHeight / 4;
    
//       if (slideInAt > panelMidpoint) {
//         SolutionPannel.current.classList.add('solution-fade-out');
//       }
    
//     }

//   return (
//     <div className='solution-main-container'>
//     <div className='solution-container' ref={SolutionPannel}>
//         <div className="solution-text1">
//        <span> Exportez </span>tous types de produits.
//         </div>

//         <div className="solution-text1">
//         <span> Négociation </span>de vos produits à l'échelle internationale.
//         </div>
//         <div className="solution-text1 last">
//          Plus de <span>sept années</span> d'expérience dans ce domaine.
//         </div>
//     </div>
//     </div>
//   )
// }

export default Solution