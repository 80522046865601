
import image1 from "../../images/home.jpg"
import image2 from "../../images/home2.jpg"

export const HomeData = [
    {
        img : image1
    },
    {
        img : image2
    },
   
]