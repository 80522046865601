import React from 'react'
import Home from './Home'
import Solution from '../Solution/Solution'
import Navbar from "../Navbar/Navbar"
import Footer from '../Footer/Footer'
import Contact  from '../Contact/Contact'
import Countries from '../Countries/Countries'
import Galerie from '../Galerie/Galerie'
import About from '../About/About'
const HomeSections = ({eng , engState}) => {
  return (
    <div className="homesection-container">
    <Navbar eng={eng} setEng={engState} />
    <Home eng={eng}  /> 
   
    <Solution eng={eng}  />
    <About  eng={eng}  />
    
    <Galerie eng={eng}  />
    <Countries eng={eng}  />
    
    <Contact eng={eng}  />
    <Footer eng={eng}  />
    </div>
  )
}

export default HomeSections