import React, { useState } from "react";
import "./home.css";
import imgHome from "../../images/home.jpg";
import { HomeData } from "./Data";
const Home = ({ eng }) => {
  const [i, setI] = useState(0);
  const [j, setJ] = useState(HomeData.length);

  setTimeout(() => {
    if (i === j - 1) {
      setI(0);
    } else {
      setI(i + 1);
    }
  }, 9000);

  return (
    <div className="Home-container">
      <div className="Home-square "></div>
      <div className="Home-left-container">
        <div className="Home-title">
        { eng?"RTCA Trading Company" :"RTCA LE SPÉCIALISTE DU COMMERCE INTERNATIONAL"}
        </div>
        <div className="Home-Description">
         {eng ? "We are a company specializing in the international export of products. We are committed to negotiating the best rates for you. If you're looking for a company you can trust, you've already found your ideal partner." :"Nous sommes une entreprise spécialisée dans l'exportation de produits à l'international. Nous nous engageons à négocier pour vous afin de vous offrir les meilleurs tarifs. Si vous recherchez une société de confiance, vous avez déjà trouvé votre partenaire idéal"} 
        </div>
        <div className="Home-btn-title-container">
          <button className="Home-btn">
            <a href="#contact">
              <div className="Home-btn-title">
                {eng?"Export Now":"Exporter maintenant"}
                <i className="uil uil-arrow-right"></i>
              </div>
            </a>
          </button>
          <div className="Home-btn-small-title">
            1.600 client
            <br />
            {eng?"satisfied":"satisfait"}
          </div>
        </div>
      </div>
      <div className="Home-right-container">
        <img alt="" src={HomeData[0].img}></img>
      </div>
    </div>
  );
};

export default Home;
