import React, { useState } from "react";
import "./navbar.css";
import logo from "../../images/logo.svg";

const Navbar = ({ eng, setEng }) => {
  const [activated, setActivated] = useState(["active", "", "", "", "", ""]);
  const [sidebar, setSidebar] = useState(false);
  return (
    <div
      className={
        sidebar
          ? "navbar-main-container active app-section"
          : "navbar-main-container"
      }
    >
      <div className={sidebar ? "navbar-container active" : "navbar-container"}>
        <ul
          className={
            sidebar
              ? " navbar-items-container active"
              : "navbar-items-container "
          }
        >
          <a href="#">
            <img
              className={sidebar ? "navbar-logo active" : "navbar-logo"}
              src={logo}
            ></img>
          </a>
          <li className="navbar-items">
            <a
              className={activated[0]}
              href="#"
              onClick={() => setActivated(["active", "", "", "", ""])}
            >
              <i className="uil  uil-question-circle nav__icon"></i>
              {eng ? "Home" : "Acceuil"}
            </a>
          </li>
          <li className="navbar-items">
            <a
              className={activated[1]}
              href="#about"
              onClick={() => setActivated(["", "active", "", "", "", "", ""])}
            >
              {" "}
              <i className="uil uil-file-alt nav__icon"></i>
              {eng ? "About" : "A propos"}{" "}
            </a>
          </li>

          <li className="navbar-items">
            <a
              className={activated[3]}
              href="#clients"
              onClick={() => setActivated(["", "", "", "active", "", "", ""])}
            >
              {" "}
              <i className="uil uil-box nav__icon"></i>
              {eng ? "Clients" : "Clients"}
            </a>
          </li>
          <li className="navbar-items">
            <a
              className={activated[4]}
              href="#contact"
              onClick={() => setActivated(["", "", "", "", "active", ""])}
            >
              <i className="uil uil-phone-volume nav__icon"></i>
              {eng ? "Contact" : "Contact"}{" "}
            </a>
          </li>

          <li className="navbar-items">
            {/* switch button */}
            <div className="navbar-switch btn">
              <input
                id="language-toggle"
                className="check-toggle check-toggle-round-flat"
                type="checkbox"
                onClick={()=>setEng(!eng)}
              />
              <label htmlFor="language-toggle"></label>
              <span className="on">EN</span>
              <span className="off">FR</span>
            </div>
            {/* switch button */}
          </li>
        </ul>
        <i
          className="uil uil-times nav__close"
          onClick={() => setSidebar(!sidebar)}
        ></i>
      </div>
      <div
        className={sidebar === false ? "nav__toggle active" : "nav__toggle"}
        onClick={() => setSidebar(!sidebar)}
      >
        <i className="uil uil-apps"></i>
      </div>
    </div>
  );
};

export default Navbar;
