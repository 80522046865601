import React,{useState, useEffect, useRef} from 'react'
import "./galerie.css"
import { GalerieData, VegFrut,Carton,Plas} from './Data'
const Galerie = ({eng}) => {
  const [data, setData] = useState([GalerieData,VegFrut])
  const [index , setIndex] =useState(0)
  setTimeout(()=>{
      if (index < data.length-1){
        setIndex(index+1)
      }
      else {
        setIndex(0)
      }
  },10000)
  return (
    <div className='galerie-container'>
      <div className="countries-title"> {eng ? "Gallery of exports":"Galerie des exportation"}</div>
 


  <div className="galerie-content" >
    {data[index].map((e,i)=>{
 return(<div className={e.hover==false?"galerie-item":"galerie-item"} key={i}>
 <img className={e.hover==false?"galerie-img hov":"galerie-img "} src={e.img} alt="Image"/>
 </div>)
    })}
    


</div>
<a href='#contact'><button className="galerie-button">{eng ? "Let us know about your product..." : "Faites nous connaître votre produit..." }</button></a>
     </div>
  )
}

export default Galerie