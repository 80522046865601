import React from 'react'
import "./about.css"
import about1 from "../../images/about1.jpg"
import about2 from "../../images/about2.jpg"
import about3 from "../../images/about3.jpg"
import about4 from "../../images/about4.jpg"
const About = ({eng}) => {
  return (
    <div className="about-container" id='about'>
      <div className="countries-title about"> {eng ?"About":"A propos"}</div>
      <div className="about-right-left-container">

        <div className="about-left">{eng ? "Welcome to RTCA, your trusted partner for exporting and trading all types of products worldwide! At RTCA, we pride ourselves on our export expertise and our ability to meet our customers' international needs.":" Bienvenue chez RTCA, votre partenaire de confiance pour l'exportation et la négociation de tous types de produits à travers le monde ! Chez RTCA, nous sommes fiers de notre expertise dans le domaine de l'exportation et de notre capacité à répondre aux besoins de nos clients à l'échelle internationale."}
       
        </div>
        <div className="about-right">
          <img src={about1} />
        </div>
      </div>


      {/* second */}
      <div className="about-right-left-container">
        <div className="about-right second">
          <img src={about2} />
        </div>
        <div className="about-left">{eng ? ` Negotiating: Our team of experienced professionals will negotiate your products with the most qualified potential customers, making sure you get the best terms and conditions. Whether you're looking to export your products abroad or establish strong business partnerships, we're here to support you every step of the way.` : "Négocier :   Notre équipe de professionnels expérimentés se chargera de négocier vos produits avec les clients potentiels les plus qualifiés, en veillant à obtenir les meilleures conditions pour vous. Que vous souhaitiez exporter vos produits à l'étranger ou établir des partenariats commerciaux solides, nous sommes là pour vous accompagner tout au long du processus. "}
          </div>

      </div>
      {/* second */}


      {/* third */}
      <div className="about-right-left-container">

        <div className="about-left">
        {eng ? "Preparation: After successfully negotiating your products, we take care of all the necessary steps to ensure that your merchandise can be exported with complete peace of mind." : "Préparer :  Après avoir négocié avec succès vos produits, nous nous occupons de toutes les étapes nécessaires pour que votre marchandise puisse être exportée en toute tranquillité."}
        </div>
        <div className="about-right ">
          <img src={about3} />
        </div>
      </div>
      {/* third */}



      {/* fourth */}
      <div className="about-right-left-container" >
        <div className="about-right second">
          <img src={about4} />
        </div>
        <div className="about-left">
          {eng ?"Exporting : Our dedicated team does everything possible to ensure that your products arrive at their destination in perfect condition. Thanks to our export expertise, we have implemented rigorous security measures to minimize the risk of damage or loss during transport.":"Exporter : Notre équipe dévouée met tout en œuvre pour assurer que vos produits arrivent à destination en parfait état. Grâce à notre expertise dans le domaine de l'exportation, nous avons mis en place des mesures de sécurité rigoureuses pour minimiser les risques de dommages ou de pertes pendant le transport."}
        </div>

      </div>
      {/* fourth */}
    </div>
  )
}

export default About



// Découvrez notre incroyable service qui vous permet de faire négocier vos produits sans effort, afin de vous connecter avec les meilleurs clients à l'échelle internationale. Nous comprenons à quel point il peut être difficile de trouver les bons partenaires commerciaux, c'est pourquoi nous avons créé cette solution pratique pour vous aider à atteindre vos objectifs.

// Notre équipe de professionnels expérimentés se chargera de négocier vos produits avec les clients potentiels les plus qualifiés, en veillant à obtenir les meilleures conditions pour vous. Que vous souhaitiez exporter vos produits à l'étranger ou établir des partenariats commerciaux solides, nous sommes là pour vous accompagner tout au long du processus.

// Grâce à notre vaste réseau international, nous avons accès à une multitude de clients dans différents secteurs d'activité. Nous nous assurerons de trouver les partenaires qui correspondent le mieux à vos besoins et à vos valeurs. Vous pourrez ainsi établir des relations commerciales durables et fructueuses.

// Ne perdez plus de temps à chercher les bons clients par vous-même. Laissez-nous prendre en charge la négociation de vos produits et profitez de notre expertise pour vous connecter avec les meilleures opportunités à l'échelle internationale. Faites confiance à notre équipe dévouée et concentrez-vous sur ce que vous faites de mieux : développer votre entreprise.

// Contactez-nous dès aujourd'hui pour en savoir plus sur notre service de négociation de produits. Nous sommes impatients de vous aider à atteindre de nouveaux sommets sur le marché international.