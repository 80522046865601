
import './css/App.css';
import { BrowserRouter as Router ,
  Route, Switch } from 'react-router-dom';
  import React ,{useState, useEffect} from 'react';
  import Loader from "./components/Loader/Loader"

  import HomeSections from './components/Home/HomeSections';

function App() {
  const [load , SetLoad] = useState(true);
  const [Eng , setEng] = useState(true);

  const changeEngState = (state) => {
      setEng(state)
  }

  useEffect (()=> {
   
    const onPageLoad = () => {
      setTimeout (()=> {
        SetLoad(false)
      },2000)
    
    }
    if (document.readyState === 'complete') {
  
      onPageLoad();
    } 
    else {
      console.log("event: onload")
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  },[]) 
  return (
    <Router>
    <div className={load ?"App" : "App loaded"}>
   

      
     {/* <Loader /> */}
     <Switch>
      <Route  exact path='/'>
       {load ? <Loader /> :<HomeSections eng={Eng} engState={changeEngState} />}
      </Route>
   
    
   
    </Switch> 
    </div>
    </Router>
  );
}

export default App;
