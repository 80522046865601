import React,{useEffect,useState,useRef} from 'react'
import "./contact.css"
import emailjs from '@emailjs/browser';

const Contact = ({eng}) => {
  const forms = useRef()
  const sendEmail = () => {
    //'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY'
    console.log(forms.current)
    emailjs.sendForm('service_8fegh1w', 'template_0gtprc7', forms.current, 'BQmeFIC1tSWICmCxM')
    .then (res => window.location.reload(true))
    .catch(err => console.log(err))
 
  }

    
  useEffect(()=> {
  
    const handleScroll = (e)=> {
   
      return panelSlideIn();
    }
    window.addEventListener('scroll', handleScroll);
    },[]);
    
     const ContactPannel = useRef();
    const panelSlideIn = () => {
          
           
      const slideInAt = (window.scrollY + window.innerHeight) -  ContactPannel.current.offsetHeight / 4;
      const panelMidpoint = ContactPannel.current.offsetTop + ContactPannel.current.offsetHeight / 4;
    
      if (slideInAt > panelMidpoint) {
      
        ContactPannel.current.classList.add('contact-fade-out');
      }
    
    }


  return (
    <div className='contact-main-container' id='contact'>
      <div className="countries-title">{eng ?"Contact us ":"Contacter nous"}</div>
    <section className="contact_wrapper" ref={ContactPannel}>
    <div className="contact_info">

    </div>
  
    <div className="contact_msg">
      <h3 className="title">
      {eng? "Export":"Exporter"}
      </h3>
     
      <form className="form_fild" ref={forms}>
        <div className="input_group w_50">
          <input type="text" className="input" required name='name'/>
          <label className="placeholder">{eng?"First Name": "Nom"}</label>
        </div>
  
        <div className="input_group w_50">
          <input type="text" className="input" required name='lastname' />
          <label className="placeholder">{eng?"Last Name": "Prénom"}</label>
        </div>
  
        <div className="input_group w_50">
          <input type="text" className="input" required name='email'/>
          <label className="placeholder">{eng?"E-mail address": "Adresse mail"}</label>
        </div>
  
        <div className="input_group w_50">
          <input type="tel" className="input" required name='phonenumber'/>
          <label className="placeholder">{eng?"Phone number":"Numéro de téléphone"}</label>
        </div>
  
        <div className="input_group w_100">
          <textarea className="input input_textarea " rows="6" required name='comment'></textarea>
          <label className="placeholder textarea">{eng?"Comment":"Commentaire"}</label>
        </div>
  
       
  
      </form>
  
          <button  className="contact-btn" onClick={() => sendEmail()} > {eng ?"Send":"Envoyer"} </button>
       
    </div>
  
  </section>
  </div>
  )
}

export default Contact