import img1 from "../../images/img1.jpg";
import img2 from "../../images/img2.jpg";
import img3 from "../../images/img3.jpg";
import img4 from "../../images/img4.jpg";
import img5 from "../../images/img5.jpg";
import img6 from "../../images/img6.jpg";
import img7 from "../../images/img7.jpg";
import veg1 from "../../images/veg1.jpg";
import veg2 from "../../images/veg2.jpg";
import veg3 from "../../images/veg3.jpg";
import fruit1 from "../../images/fruit1.jpg";
import fruit2 from "../../images/fruit2.jpg";
import fruit3 from "../../images/fruit3.jpg";
import plas1 from "../../images/plas1.jpg";
import plas2 from "../../images/plas2.jpg";

import plas4 from "../../images/plas4.jpg";
import plas5 from "../../images/plas5.jpg";
import plas6 from "../../images/plas6.jpg";
import plas7 from "../../images/plas7.jpg";
import plas8 from "../../images/plas8.jpg";
import plas9 from "../../images/plas9.jpg";
import plas10 from "../../images/plas10.jpg";
export const GalerieData = [
  { img: img5, hover: true },
  { img: img2, hover: true },
  { img: img6, hover: false },
  { img: img4, hover: true },
  { img: img7, hover: true },
];

export const VegFrut = [
  { img: veg1, hover: true },
  { img: veg2, hover: true },
  { img: veg3, hover: false },
  { img: fruit2, hover: true },
  { img: fruit3, hover: true },
];

export const Plas = [
    { img: plas1, hover: true },
    { img: plas2, hover: true },
    { img: img1, hover: false },
    { img: plas4, hover: true },
    { img: plas5, hover: true },
  ];

  export const Carton = [
    { img: plas6, hover: true },
    { img: plas7, hover: true },
    { img: plas8, hover: false },
    { img: plas9, hover: true },
    { img: plas10, hover: true },
  ];
