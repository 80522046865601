import React from 'react'
import "./footer.css"
import { FaInstagram, FaEnvelope, FaFacebook } from 'react-icons/fa';

import { AiFillLinkedin,AiFillInstagram,AiTwotonePhone,AiOutlineTwitter } from 'react-icons/ai';

const Footer = ({eng}) => {
  return (
    <section className="hero ">
  <div className="content footer-basic ">

            <ul>
                <li><a href="#">{eng ?"Home":"Acceuil"}</a></li>
                <li><a href="#about">{eng ?"About":"A propos"}</a></li>
                <li><a href="#contact">{eng ?"Contact":"Contact"}</a></li>
            </ul>
            <div className='line'></div>
            <div className='footer'>                
            <div className="social"><a href="https://www.facebook.com/profile.php?id=61551641532905"><FaFacebook className="icon" title='rtca' /></a>
            <a href="https://www.instagram.com/rtca.trading/"><AiFillInstagram className="icon" title='rtca.trading'/></a>
            <a href="https://www.linkedin.com/company/rtca-international-commerce/mycompany/" title='rtca(trading company)'><AiFillLinkedin className="icon" /></a>
            <a href="https://twitter.com/RTCATrading" title='RTCATrading'><AiOutlineTwitter className="icon" /></a>
            <a href=""><FaEnvelope className="icon" title='info@rtca.tn' /></a>
            <a href="whatsapp://send?phone=+21655648681&text=Hello%2C%20can i know more about your services!"  ><AiTwotonePhone className="icon" title='+216 55 648 681 ' /></a>
            </div>
            <p className="copyright">{eng ?"Trading company © 2023 ":"Commerce Interantionale © 2023"}</p>
            </div>


   

    </div>
  <div className="waves"></div>
</section>
  )
}

export default Footer