
import italy from "../../images/italy.svg"
import china from "../../images/china.svg"
import portugal from "../../images/portugal.svg"
import spain from "../../images/spain.svg"
import turkey from "../../images/turkey_flag_icon_228696.svg"
import dubai from "../../images/Dubai.svg"

export const CountriesData = [
    {
     image: italy,
     nameFr: "ITALIE",
     nameEn :"ITALY",
     gray: true
    },
    {
        image: china,
        nameFr: "chine",
        nameEn :"china",
        gray: true
    },
    {
        image: portugal,
        nameFr: "Portugal",
        nameEn :"portugal",
        gray: true
    },
    {
        image: spain,
        nameFr: "Espagne",
        nameEn :"spain",
        gray: true
    },
    {
        image: turkey,
        nameFr: "Turquie",
        nameEn :"turkey",
        gray: true
    },
    {
        image: dubai,
        nameFr: "Dubai",
        nameEn :"Dubai",
        gray: true
    },
]


// LABENA ,
// lexia explore
// come to pola
// sell trader 
// she can  find a partener 
// ceo 
//  easiest with partener
// not c
// come as a tourrist  we can open the 
// minimum 1200 EURO DEPOSITING YOUR BANK ACCOUNT as equity 
//  feet 500 $ to open the company to register they will do everything 
//  legall adresee official to receive mail they  will scan it and send it 
// estimate for the  company 
// resident with the company 
// 20,0000  TO SEELLL THE COMPANY 
// australia easy 
// same name  but the process will be like 
// 


// -- all about the company 💼
// --- business partner or solo: (they will search for a business partner according to your availbility)
// --- come to  poland with a tourist visa and they will open the company  arround 7 days 
// ---  provide you with legall adress to receive letters and they will scan the lettes for you and send them to tunisia 
// --- to  open the company you have to pay 500 $
// --- deposit a minimum in your bank account 1200 
// --- after openning the company you can go back to tunisia and start poland visa procudure
// -- business visa tourist 
// -- boland visa only for poland 



// --- all about the familly residance 👨‍👨‍👧‍👦:
// --- stay until the procedure complete for bringing the family 
// ---  in order to bring the family for residance : 20,0000 $ as benifits of the company or an input to the company (! not export)


/// vip domaine